// import { useState, useEffect } from 'react';
// import '@aws-amplify/ui-react/styles.css';
// import { type AuthUser } from "aws-amplify/auth";

import './App.css';
// import Error404 from './pages/error404';
import LoginPage from './pages/login/loginPage';
import Logout from './pages/login/logoutPage';
import ImgUpload from './pages/imgUpload/imgUpload';
import HomePage from './pages/home/homePage';
import NavBar from './common/components/navbar/nav-bar';
// import ComingSoon from './pages/coming-soon/coming-soon';
import AllAdmins from './pages/admins/allAdmins';
import Login from './pages/login/login-page';

import Layout from './common/components/layout/Layout';
// import Public from './common/components/layout/Public';
// import Login from './redux/api/auth/Login';
import Welcome from './redux/api/auth/Welcome';
import RequireAuth from './redux/api/auth/RequireAuth';
import WithoutAuth from './redux/api/auth/WithoutAuth';


import { Routes, Route } from "react-router-dom";

// providers
// import AppProviders from './providers';
// import { FaTruckLoading } from 'react-icons/fa';





const App: React.FC = () => {

  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path = "/" element = {<Layout />}>
            {/* public routes  */}
            <Route index element = {<HomePage />} />
            <Route element = {<WithoutAuth /> }>
              {/* <Route path = "login" element={<LoginPage />} /> */}
              <Route path = "login" element={<Login /> } />
            </Route>

            {/* Protected Routes  */}
            <Route element = {<RequireAuth />}>
              <Route path = "welcome" element={<Welcome />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="admins/view" element = {<AllAdmins />} />
              <Route path="/imageUpload" element = {<ImgUpload />} />
            </Route>
        </Route>
        {/* <Route path="/" element = {<HomePage />} />
        <Route path="/coming-soon" element = {<ComingSoon />} />
        <Route path="/signup" element = {<LoginPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/imgUpload" element = {<ImgUpload />} />
        <Route path="admins/view" element = {<AllAdmins />} />
        <Route path="/*" element = {<Error404 />} /> */}
      </Routes>
    </div>
  );
}

export default App;

import { Col, Row , Container, Form, FormGroup, Label, Input, Button} from "reactstrap";
import { useState, useEffect } from 'react';
import { useLazyGetAllAdminsQuery, Admin } from '../../redux/api/auth/authApiSlice';
import "./allAdmins.css";
// import axios from "axios";


const AllAdmins = () => {
    // Create the submit method.
    const [isAuth, setIsAuth] = useState(false)
    const [dataOut, setDataOut] = useState<any[]>()
    const [trigger, { isLoading, isError, data, error}] = useLazyGetAllAdminsQuery()

    useEffect (() => {
      console.log("data")
      trigger()
      console.log(data)
      if(data){
        // refetch()
        setDataOut(data.admins as any)}
    }, [data, trigger])


  return (
       dataOut? <Container fluid className = "viewall-admin-page Page gx-0">
            <Row className="w-100 viewall-admin-page-row g-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Container fluid className="h-100 d-inline-flex flex-column w-100 align-items-center justify-content-center viewall-admin-container">
          
                <table>
                  <thead><tr>
                    <th>Id</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>User Type</th>
                    <th>Is Active</th>
                    <th>Is Staff</th>
                    <th>Is SuperUser</th>
                    <th>Last Login</th>
                    <th>Date Joined</th>
                  </tr></thead>
                  <tbody>
                    {dataOut.length > 0 ? 
                        dataOut.map((item: Admin) => (
                          
                        <tr key={`item-${item.user.id}`}>
                          <td><code>{item.user.id}</code></td>
                          <td><code>{item.first_name}</code></td>
                          <td><code>{item.last_name}</code></td>
                          <td><code>{item.user.email}</code></td>
                          <td><code>{item.user.user_type}</code></td>
                          <td><code>{item.user.is_active? "Yes" : "No"}</code></td>
                          <td><code>{item.user.is_staff? "Yes" : "No"}</code></td>
                          <td><code>{item.user.is_superuser? "Yes" : "No"}</code></td>
                          <td><code>{item.user.last_login}</code></td>
                          <td><code>{item.user.date_joined}</code></td></tr>
                        
        )) : <></>}</tbody></table>
                        
                </Container>
            </Col>
            </Row>
        </Container> :
        <>isLoading</>
  );
}

export default AllAdmins;

import { useLogoutMutation } from '../../redux/api/auth/authApiSlice'
import { useRef, useState, useEffect } from 'react'
// import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import { useLocation, useNavigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectCurrentToken, setCredentials, selectRefreshToken, logOut } from "../../redux/api/auth/authSlice"

    

function Logout () {
  const [logout, {isLoading}] = useLogoutMutation()
  const token = useSelector(selectCurrentToken)
  const rToken = useSelector(selectRefreshToken)
  const [sess, setSess] = useState('')
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

    useEffect(() => {
       (async () => {
        try{
          console.log("Tokens")
          console.log("token : " + token)
          console.log("Refresh : " + rToken)
          const userData = await logout({refresh_token : rToken}).unwrap()
          // if(userData){dispatch(logOut())}
          dispatch(logOut())
          
          // console.log(userData)
          // dispatch(setCredentials({...userData, sess}))
          // navigate('/')
      } catch (err: any) {
          if(err.status === 401){
            console.log("Backend credentials expired, destroying client credentials")
            dispatch(logOut())
          }
          if(!err?.response){
              console.log('No Server Response')
          }
          else if(err.response?.status === 400){
              console.log('Missing Username or Password')
          }
          else if(err.response?.status === 401){
              console.log('Unauthorized')
          }
          else{
              console.log('Login Failed ...!!')
          }
          // errRef.current.focus()
      }
         })();
    }, []);
    return (
       <div></div>
     )
}

export default Logout
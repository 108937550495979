import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../auth/authSlice';


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    credentials: 'include',
    prepareHeaders: (headers: any, { getState } : any) => {
        const token = (getState() as any).auth.token
        if(token){
            headers.set("authorization", `Bearer ${token}`)
        }
        // headers.set("Content-Type", "multipart/form-data")
    }
})

// const baseQueryNoHeader = fetchBaseQuery({
//     base
// })

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions)
    console.log("query auth")
    console.log(args)
    console.log(api)
    console.log(extraOptions)
    console.log("result")
    console.log(result)
    if(result?.error?.status === 403){
        console.log('Sending refresh token')
        const refreshResult = await baseQuery('users/token/refresh/', api, extraOptions)

        if(refreshResult?.data){
            const user = api.getState().auth.user
            //store the new token
            api.dispatch(setCredentials({...refreshResult.data, user}))
            //retry original query with new access token
            result = await baseQuery(args, api, extraOptions)
        }
        else{
            api.dispatch(logOut(api.getState()))
        }
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth, 
    tagTypes: ['Resp', 'Admin', 'Admins'],
    endpoints: builder => ({})
})

// export const 
import { apiSlice } from "../api/admin.api"

type User = {
    is_active : Boolean,
    is_staff : Boolean,
    is_superuser : Boolean,
    email : string,
    id : string,
    user_type : string,
    last_login: string,
    date_joined: string
}
export type Admin = {
    user : User,
    first_name : string,
    last_name : string
}

export type Resp = {
    admins : Admin[],
    message : string,
}

// apiSlice.enhanceEndpoints({addTagTypes : ['Resp']})
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url : 'users/token/',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        logout: builder.mutation({
            query: credentials => ({
                url : 'users/logout/',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        getAllAdmins: builder.query<Resp,void>({
            query: () =>  ({url : 'users/admins/get/'}),
            providesTags: ['Resp', 'Admin', 'Admins']
        }),
        postAdmin: builder.mutation({
            query: formData => ({
                url : 'users/admins/post/',
                method : 'POST',
                body : {...formData},
                // prepareHeaders: (headers: any) => {
                //     headers.set("Content-Type", "multipart/form-data")
                //     // return headers
                //     },
            })
        }),
        getUserName: builder.query<{first_name : string, last_name: string}, string>({
            query: (id: string) => `username/get/${id}`
        }),
        uploadImageMulti: builder.mutation({
            query: formData => ({
                url : 'phantom_menance/image/upload/',
                method: 'POST',
                body : { ...formData }
            })
        })
    })
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useLazyGetAllAdminsQuery,
    usePostAdminMutation,
    useGetUserNameQuery,
    useUploadImageMultiMutation,
} = authApiSlice
// import logo from '../../../logo.svg';
import { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';

function HomePage() {

  // const [data, setData] = useState("");
  // useEffect(() => {
  //   if(localStorage.getItem('access_token') === null){                   
  //     window.location.href = '/signup'
  //   }
  //   else{
  //     setData("Hi you are logged in")
  //   };
  // }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p><code>Coming Soon</code></p>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p><code>This website is under development</code></p>
        <Link to = "/login">Employee Login</Link>
        {/* <p><code>{data}</code></p> */}
      </header>
    </div>
  );
}

export default HomePage;

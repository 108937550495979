import logo from '../../../logo.svg';
import React from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody, CardTitle, CardSubtitle} from "reactstrap";
import { useState, useRef, useCallback, useEffect} from 'react';
import { FaPlus } from "react-icons/fa";
import { useUploadImageMultiMutation } from '../../redux/api/auth/authApiSlice';

interface ImageORM {
    image: string;
    name: string;
    size: number;
    type: string;
}

function ImgUpload() {
  const [showSignUpAdmin, setShowSignUpAdmin] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // const [ data ] = usePostAdminMutation()
    const [user, setUser] = useState('')
    // const dispatch = useDispatch()
    // const navigate = useNavigate()
    const [ uploadImages, { isLoading}] = useUploadImageMultiMutation()
    const [ userErr, setUserErr] = useState<any>()

    const [imgUploaded, setImgUploaded] = useState<any>()
    const [imgUrls, setImgUrls] = useState<any>([])
    const [Image, setImage] = useState<any>()
    const imgUploadRef = useRef<HTMLInputElement>(null)
    const handleImgUploadClick = useCallback(() => imgUploadRef.current?.click(), [])
    const acceptedImageTypes = ".gif,.jpg,.jpeg,.png,.pdf";
    const acceptMultiple = true;


    const handleFileUpload = (e: any) => {
        e.preventDefault();
        const uploadedFiles = e.target.files
        const numberOfUploadedFiles = uploadedFiles.length
        console.log(numberOfUploadedFiles+" files selected")

        let formData :any = []
        for(let i = 0; i < numberOfUploadedFiles; i++){
            const reader = new FileReader();
            reader.onload = () => {
                var res = reader.result
                if(typeof res === "string"){
                    console.log("String")
                    var blocks = res.split(";")
                    const realData = blocks[1].split(",")[1];
                    formData.push(realData)
                }
            }
            reader.onerror = (error) => console.error(error)
            reader.readAsDataURL(uploadedFiles[i])
        }
        setImage(formData)

        const temp = e.target.files;
        // console.log("Files")
        // console.log(temp[0])
        // console.log(reader.readAsDataURL(temp[0]))

        let temp1: any = []
        if(temp.length>0 && temp){
            setImgUploaded(temp)
            for(let i = 0; i < temp.length; i++ ){
                const objectUrl = URL.createObjectURL(temp[i])
                if(!(objectUrl in temp1)){
                    temp1.push(objectUrl)
                }
            }
            if(temp1.length = temp.length){
                setImgUrls(temp1)
            }
        }


    }
    // Create the submit method.
    const submit = async (e:any) => {
        e.preventDefault();
        let returnObject : ImageORM[] = []

        const reader = new FileReader()
        reader.onload = () => {
            if(reader.result){
                let res = reader.result
                var blocks = String(res).split(";");
                const realData = blocks[1].split(",")[1];
                setImage(realData);
            }
            
        };
        reader.onerror = (error) => console.error(error);

        if(imgUploadRef && imgUploadRef.current && imgUploadRef.current.files){
            if(imgUploaded && imgUrls.length > 0){
                for(let i = 0; i<imgUrls.length; i++){
                    console.log(imgUploadRef.current.files[i])
                    console.log(imgUploaded[0])
                    // let imgTemp = reader.readAsDataURL(imgUploaded[i])
                    // console.log(imgTemp)
                    let imgTemp = "hi"
                    console.log(imgTemp)
                    returnObject.push({
                        image: Image[i],
                        name: imgUploadRef.current.files[i].name,
                        size: imgUploadRef.current.files[i].size,
                        type: imgUploadRef.current.files[i].type
                    })
                }
                try{        
                    const imgData = await uploadImages({images: returnObject}).unwrap()
                } catch (err : any) {
                    console.log("Err found")
                    console.log(err)
                }
            }
        }
        
    }

  return (
      <Container fluid className = "login-page Page gx-0">
            <Row className="w-100 login-page-row g-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="left">
                    <Container fluid className="h-100 d-inline-flex flex-column w-100 align-items-center justify-content-center login-container">
                    <Row className = {`signup-admin-box`} xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                            <Col className = "signup-admin-header flex-column">
                                <Container fluid className="d-inline-flex flex-column h-100">
                                    <Row className="d-inline-flex flex-column h-100">
                                        <Col className="d-flex justify-content-center align-self-center" >
                                            <h2 className="align-self-center header">Upload Image</h2>
                                        </Col>
                                        <Col className="d-flex justify-content-center align-self-center">
                                            <span className="align-middle align-self-center caption">This is a possible description</span>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Container fluid className="">
                                <Row className="justify-content-center">
                                    <Col xs={12} sm={9} lg={9} xl={8} xxl={8}>
                                        <Form className="h-100 justify-content-center d-flex flex-column" onSubmit={submit} encType="multipart/form-data">
                                            <FormGroup>
                                                <Label for="upload-image-label" hidden>Upload Image</Label>
                                                <Input innerRef = {imgUploadRef} type = "file" name = "upload-image" id = "upload-image" placeholder="Add Image"
                                                    required
                                                    accept = {acceptedImageTypes}
                                                    multiple = {acceptMultiple}
                                                    onChange={handleFileUpload} />
                                                    {userErr?.email ? <code>{userErr.email[0]}</code> : <></>}
                                                <Card>
                                                  <CardBody>
                                                    <CardTitle tag="h5">{imgUploaded? "Images Added": "Upload Images"}</CardTitle>
                                                    <CardSubtitle tag="h6" className="mb-2 text-muted">{imgUploaded? "Uploaded images ... " : "Upload images"}</CardSubtitle>
                                                  </CardBody>
                                                  {imgUrls.length>0?<CardBody>
                                                    {imgUrls.map((img:any) => 
                                                        <object data={img} key={img} type="application/pdf" width="35" height="60"></object>
                                                        // <img width="40" height="40" key={img} src={img}/>
                                                        )}</CardBody>
                                                  :
                                                    <CardBody onClick = {handleImgUploadClick}><FaPlus size="40" /></CardBody>

                                                  }
                                                </Card>
                                            </FormGroup>
                                            {/* <FormGroup>
                                                <Label for="signup-admin-first-name" hidden>FirstName</Label>
                                                <Input type = "text" name = "signup-admin-first-name" id = "signup-admin-first-name" placeholder="First Name" value={firstName} autoComplete = "first name auto complete"
                                                    required
                                                    onChange={e => setFirstName(e.target.value)} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="signup-admin-last-name" hidden>LastName</Label>
                                                <Input type = "text" name = "signup-admin-last-name" id = "signup-admin-last-name" placeholder="Last Name" value={lastName} autoComplete = "last name auto complete"
                                                    required
                                                    onChange={e => setLastName(e.target.value)} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="signup-admin-password" hidden>Password</Label>
                                                <Input type = "password" name = "signup-admin-password" id = "signup-admin-password" placeholder="Password" value={password} autoComplete = "pass autocomplete" 
                                                    required
                                                    onChange={e=> setPassword(e.target.value)}/>
                                            </FormGroup> */}
                                            {/* <FormGroup>
                                                <Label for="signup-admin-retype-password" hidden>Re-Enter Password</Label>
                                                <Input type = "password" name = "signup-admin-retype-password" id = "signup-admin-retype-password" placeholder="Retype Password" autoComplete = "pass retype autocomplete" />
                                            </FormGroup> */}
                                            <Button type = "submit">Upload Image</Button>
                                    </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                        
                        
                        
                    </Container>
                </Col>
                {/* <Col xs={12} sm={12} md={5} lg={5} xl={5} xxl={5} className="right">
                    <Container fluid className="d-inline-flex flex-column h-100 justify-content-center align-self-center">
                        <Row className="d-inline-flex flex-column">
                            <Col className="p-1">
                                <h4 className="align-middle align-self-center">We can put some text over here</h4>
                            </Col>
                            <Col className="p-2">
                                <h1 className="align-self-center">AND SOME MORE TEXT</h1>
                            </Col>
                            <Col className="p-1">
                                <span className="align-middle align-self-center">and a long cool paragraph about some thing randome that might seem important to be shared. This is an absolutely random text paragraph and please stop reading this now. STOP</span>
                            </Col>
                            <Col className="p-0">
                                <span className="align-middle align-self-center"><code>and some more text as well !!!</code></span>
                            </Col>
                        </Row>
                    </Container>
                </Col> */}
                
            </Row>
        </Container>
  );
}

export default ImgUpload;

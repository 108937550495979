import { useState, useEffect } from 'react';
import { FaBars } from "react-icons/fa";
import { useSelector } from "react-redux"
import { selectCurrentToken, selectCurrentUserName } from "../../../redux/api/auth/authSlice"
import { useLocation } from "react-router-dom"
import "./navbar.css";
import {
  NavItem,
  NavLink,
  Nav
} from 'reactstrap';

const NavBar = () => {
    const [showNavbar, setShowNavbar] = useState(false)
    const [muteNavbar, setMuteNavbar] = useState(false)
    const [isAuth, setIsAuth] = useState(false)

    const token = useSelector(selectCurrentToken)
    const user = useSelector(selectCurrentUserName)
    const location = useLocation()

    useEffect(() => {
      if(token){
        setIsAuth(true);
      }
      else{
        if(isAuth){
          setIsAuth(false)
        }
      }
    }, [isAuth, token])


  
    const handleShowNavbar = () => {
        setMuteNavbar(false)
        setShowNavbar(!showNavbar)
    }
    
    const muteNavbarAction = () => {
        if(window.scrollY>80 && !showNavbar){
            setMuteNavbar(true)
        }
        else{
            setMuteNavbar(false)
        }
    }
    window.addEventListener("scroll", muteNavbarAction);

    const returnUserName = (user: any) => {
      if(token){return 'Welcome : ' + user}
    }

    const returnAdminsOption = () => {
      if(token){return <>
        <NavItem>
          <NavLink href="/admins/view">ADMINS</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/imageUpload">Image Upload</NavLink></NavItem>
        </>
      }
    }

    return (
      <Nav className={`navbar ${muteNavbar && 'muted' }`} pills>
      
        <div className="container">
        <div className="logo">
            {/* //<Brand /> */}
          </div>
          <div className="menu-icon" onClick={handleShowNavbar}>
            <FaBars />
          </div>
          <div className={`nav-elements  ${showNavbar && 'active'}`}>
            <ul>
              
                <NavItem><NavLink href="/">HOME</NavLink></NavItem>
              
                <NavItem><NavLink href="/coming-soon">COMING SOON</NavLink></NavItem>
              
                <NavItem><NavLink href="/team">TEAM</NavLink></NavItem>
              
                <NavItem><NavLink href="/contact">CONTACT</NavLink></NavItem>

                {returnAdminsOption()}
                { <p>{returnUserName(user)}</p> }
              
              
            </ul>
            
          </div>
    
                <NavItem>{isAuth ? <NavLink href="/logout">Logout</NavLink> : <NavLink href="/login">SIGN UP</NavLink>}</NavItem>
        
          
            
        </div>
      </Nav>
    )
  }
  
export default NavBar
import { configureStore } from '@reduxjs/toolkit'
import authReducer from "../api/auth/authSlice"
import { apiSlice } from "../api/api/admin.api"
// import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux";

const persistConfig = {
    key: 'root', 
    storage
}

const reducers = combineReducers({
        [apiSlice.reducerPath] : apiSlice.reducer,
        auth: authReducer    
   });

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleWare => 
        getDefaultMiddleWare({
            serializableCheck : false
            //remove serialiizable error we were getting on our consoles since this serializercheck is getting depricated
        }).concat(apiSlice.middleware),
    devTools: true
})

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
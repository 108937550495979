import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: {user : null, token : null, refresh : null, first_name : null, last_name : null},
    reducers: {
        setCredentials: (state : any, action) => {
            const { refresh, access, user, first_name, last_name } = action.payload
            state.user = user
            state.token = access
            state.refresh = refresh
            state.first_name = first_name
            state.last_name = last_name
        },
        logOut: (state) => {
            console.log("Logout")
            // console.log(action.payload)
            state.user = null
            state.token = null
            state.refresh = null
            state.first_name = null
            state.last_name = null
        }
    }
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: any) => state.auth.user
export const selectCurrentToken = (state: any) => state.auth.token
export const selectRefreshToken = (state: any) => state.auth.refresh
export const selectCurrentUserName = (state: any) => state.auth.first_name + " " + state.auth.last_name
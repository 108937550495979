import React from 'react';

const Login: React.FC = () => {
  return (
    <div className="login-container">
      <h2>Login</h2>
      {/* Login form */}
      {/* Input fields for email and password */}
      <button className="login-button">Login</button>
    </div>
  );
};

export default Login;